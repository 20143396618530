@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: "DMSans";
    src: local("DMSans"),
     url("./Fonts/DMSans-Regular.ttf") format("truetype");
    font-weight:100;
}
@font-face {
    font-family: "DMSans";
    src: local("DMSans"),
     url("./Fonts/DMSans-Medium.ttf") format("truetype");
    font-weight:300;
}
@font-face {
    font-family: "DMSans";
    src: local("DMSans"),
     url("./Fonts/DMSans-Bold.ttf") format("truetype");
    font-weight:500;
}



@font-face {
    font-family: "Akira";
    src: local("Akira"),
     url("./Fonts/Akira.otf") format("opentype");
    font-weight:100;
}
    @font-face {
        font-family: "Akira";
        src: local("Akira"),
         url("./Fonts/Akira Bold.otf") format("opentype");
        font-weight:300;
}
    @font-face {
        font-family: "Akira";
        src: local("Akira"),
         url("./Fonts/Akira Super Bold.otf") format("opentype");
        font-weight:500;
}
    @font-face {
        font-family: "Akira";
        src: local("Akira"),
         url("./Fonts/Akira Outline.otf") format("opentype");
        font-weight:100;
        font-style:italic;
}
