.loading-container
{
   
    pointer-events: none;
    position:fixed;
    z-index: 999;
}
.loading-background
{ 
    transition:all 2.0s ease-out;
    display:flex;
    align-content: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    width:100vw;
    height:100vh;
    background:rgb(51, 51, 51);

}
.progress {
    transition:all 0.4s ease-out;
    background: rgb(51, 51, 51);
    border-radius: 1px;
    height: 15px;
    width: 100px;
}

.progress2 {
    transition:all 0.4s ease-out;
    content: '';
    display: block;
    background: rgb(255, 255, 255);
    width: 9999;
    height: 100%;
    border-radius: 1px;
}
